import typeActions from '../../../utils/typeActions'

const {
    actions,
    types
} = typeActions('company', {
    setSelectedCompany: (data) => ({
        payload: data
    }),

    setCommentariesType: (data) => ({
        payload: data
    }),

    setCommentaries: (data) => ({
        payload: data
    }),

    getAsyncCommentaries: (data) => ({
        payload: data
    })
})

export {
    actions,
    types
}