import React, { useEffect, useState } from 'react'
import './styles.scss'
import { useDispatch, useSelector } from 'react-redux'
import companyServices from '../../../services/companyServices'
import { actions as companyAction } from '../../../store/modules/company/actions'
import CommentsSkeleton from '../CommentsSkeleton'

//import { retry } from 'redux-saga/effects'


const CompanyInfos = () => {

  const { selectedCompany: company } = useSelector((state) => state.company)
  // const {
  //   selectedCompany,
  //   commentariesType,
  //   commentaries,
  // } = useSelector((state) => state.company)

  // const dispatch = useDispatch();

  // useEffect(() => {
  //   dispatch(companyAction.setCommentariesType(''))
  // }, [])


  // useEffect(() => {
  //   dispatch(companyAction.getAsyncCommentaries({
  //     companyId: selectedCompany.Id,
  //     type: commentariesType,
  //     offset: 0,
  //     qty: 10,
  //   }))
  // }, [commentariesType])


  // const customClass = (type = '') => {
  //   return type === commentariesType ? 'active' : ''
  // }

  return (
    <>
      <div className="company-infos">
        <p>Sobre</p>
        <div className="company-infos__description">
          <p>{ company.CompanyDescription }</p>
          {/* <p> Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. </p> */}
        </div>
        <hr />
        <div className="social-media">
           {/* <span className="fa fa-facebook" aria-hidden="true"></span>
          <span className="fa fa-instagram" aria-hidden="true"></span>
          <span className="fa fa-linkedin" aria-hidden="true"></span> */}
          {/* <span className="fa fa-globe" aria-hidden="true"></span><a href={`https://${company.StoreUrl}`} target="_blank">{`https://${company.StoreUrl}`}</a> */}       
         
          <span className="fa fa-globe" aria-hidden="true"></span><a href={company.StoreUrl} target="_blank">{company.StoreUrl}</a>
        </div>

      </div>
    </>
  )
}

export default CompanyInfos
