import React from 'react'
import Home from '../views/Home/index';
import SobreNos from '../views/SobreNos/index';
import Lojista from '../views/Lojista/index';
import NovoLojista from '../views/NovoLojista';
import Medalhas from '../views/Medalhas';
import Consumidor from '../views/Consumidor/index';
import RSorteio from '../views/Consumidor/regulamentoSorteio';
import PremioEbit from '../views/PremioEbit/index';
import PremioRegulamento from '../views/PremioEbit/regulamento';
import AwardWinners from '../views/PremioEbit/awardWinners';
import Webshoppers from '../views/Webshoppers/index';
import WebshoppersPremium from '../views/Webshoppers/premium';
import WebshoppersCustom from '../views/Webshoppers/custom';
import CompanyReputation from '../views/CompanyReputation';
import CompanyReputationDetails from '../views/CompanyReputationDetails';
import StoreProvider from '../store/Provider';
import ScrollTop from '../components/ScrollTop';

import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';


export default () => {
  return (
    <Router basename='/'>
      <ScrollTop>
        <StoreProvider>
          <Switch>
            <Route path="/" exact component={Home} />
            {/* <Route path="/sobre-nos" exact component={SobreNos} /> */}
            {/* <Route path="/insights" exact component={Insights} /> */}
            {/* <Route path="/insights/news" exact component={News} /> */}
            {/* <Route path="/lojista" exact component={Lojista} /> */}
            {/* <Route path="/empresa" exact component={NovoLojista} /> */}
            {/* <Route path="/reativar-loja" exact component={ReactivateStore} /> */}
            {/* <Route path="/medalhas-ebit" exact component={Medalhas} /> */}
            {/* <Route path="/consumidor" exact component={Consumidor} /> */}
            <Route path="/consumidor/regulamento-sorteio" exact component={RSorteio} />
            {/* <Route path="/login" exact component={UserLogin} /> */}
            {/* <RoutesPrivate path="/portal-usuario" exact component={Associate} /> */}
            {/* <Route path="/premio-ebit" exact component={PremioEbit} /> */}
            <Route path="/premio-ebit-regulamento" exact component={PremioRegulamento} />
            <Route path="/premio-ebit-vencedores" exact component={AwardWinners} />
            {/* <Route path="/webshoppers" exact component={Webshoppers} /> */}
            {/* <Route path="/webshoppers-premium" exact component={WebshoppersPremium} /> */}
            {/* <Route path="/webshoppers-capitulos" exact component={WebshoppersCustom} /> */}
            <Route path="/reputacao-de-lojas" exact component={CompanyReputation} />
            <Route path="/:urlName" exact component={CompanyReputationDetails} />
            <Route path="/:urlName/selo" exact component={CompanyReputationDetails} />
            <Redirect from="*" to="/" />
          </Switch>
        </StoreProvider>
      </ScrollTop>
    </Router>
  );
};