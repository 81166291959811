import {
  types
} from './actions'


const INITIAL_STATE = {
  selectedCompany: {},
  commentariesType: '',
  commentaries: {
    loading: false,
    data: [],
    error: false
  }
}

export default (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
    case types.SET_SELECTED_COMPANY:
      return {
        ...state,
        selectedCompany: action.payload,
      }

      case types.SET_COMMENTARIES_TYPE:
        return {
          ...state,
          commentariesType: action.payload,
        }

        case types.SET_COMMENTARIES:
          return {
            ...state,
            commentaries: {
              ...state.commentaries,
              ...action.payload
            },
          }

        default:
          return state
  }
}