import React from 'react';
import './style.scss'
import { Link } from 'react-router-dom'

import Menu from '../../components/Menu'
import Footer from '../../components/Footer'
import MedalsEbit from '../../components/MedalsEbit-Link'

import ImgEbit from '../../assets/imagens/img-aboutUs02.66c09e19.jpg'
import ImgEbit2 from '../../assets/imagens/img-aboutUs01.e9428cae.jpg'
import ImgGanhadores from '../../assets/imagens/img-ganhadores.jpg'
import ImgGanhadores2 from '../../assets/imagens/img-ganhadores2.jpg'
import ImgLogin from '../../assets/imagens/img-login.png'
import ImgCons from '../../assets/imagens/img-consumidor2.e9ef169e.JPG'
import ImgCons3 from '../../assets/imagens/img-consumidor3.40d1bdeb.JPG'

import ConsBanner1 from '../../assets/imagens/Img-banner-Consumidor-Ebit-03.05b67b40.png'
import ConsBanner2 from '../../assets/imagens/consumidor-banner.f9aa8bb1.JPG'


function Consumidor() {
  return (
    <>
      <Menu></Menu>

      <section className="banner-premio-ebit">
        <div className="banner-premio-ebit__img-premio1">
          <img src={ConsBanner1} alt="Banner Lojista"/>
          <div>
            <img src={ConsBanner2} alt="Segundo Banner Lojista"/>
          </div>
        </div>
      </section>

      <div className="about-ebit-text container">
        <h2>consumidor</h2>
        <div>
          <p className="lead">A Ebit | Nielsen é a <strong>maior plataforma de opinião de consumidores do Brasil</strong> em que, varejistas cadastrados recebem medalhas (Bronze, Prata, Ouro e Diamante) de acordo com a opinião de consumidores em pesquisas de satisfação de experiências reais de compra, conforme os seguintes critérios: quantidade de pesquisas respondidas, porcentagens de entregas no prazo e probabilidade dos consumidores voltarem a comprar na loja.</p>
          <img src={ImgEbit2} alt="" />
        </div>
      </div>

      <div className="about-ebit-text container">
        <div>
          <img src={ImgEbit} alt="" className="img-responsive"/>
          <p className="lead"><strong>A mensuração do mercado brasileiro de e-commerce também é feita pela Ebit | Nielsen</strong> por meio de indicadores de desempenho de vendas e de pesquisas de comportamento de compra do comércio eletrônico de lojas colaboradoras, usando metodologias de coleta digital de <strong> informações relevantes e estratégicas </strong>que permitem o acompanhamento das <strong>tendências do e-commerce como um todo.</strong></p>

        </div>
      </div>

      <div className="sorteio container">
        <h2>É fácil participar dos nossos sorteios!</h2>

        <h5>Confira nosso regulamento <Link to="/consumidor/regulamento-sorteio">clicando aqui</Link> e participe</h5>

        <h6>Há três formas de concorrer aos prêmios sorteados pela Ebit | Nielsen :</h6>

        <div className="sorteio__passos">
          <div className="passos-detalhes">
            <h6>1. cadastro ebit | nielsen</h6>
            <p className="lead">Concluindo em 100% seu cadastro no portal Ebit | Nielsen</p>
          </div>
          <div className="passos-infos">
            <p className="lead">Para que esteja apto a essa etapa, necessariamente já deve ter respondido uma de nossas pesquisas ao comprar online.
                Conclua seu cadastro <br/> <a href="https://company.ebit.com.br/user/login">clicando aqui</a></p>
            <img src="" alt="" />
          </div>
        </div>

        <div className="sorteio__passos">
          <div className="passos-detalhes">
            <h6>2. pesquisa experiência de compra</h6>
            <p className="lead">Respondendo nossas pesquisas online de satisfação</p>
          </div>
          <div className="passos-infos">
            <p className="lead">Toda vez que comprar nas lojas conveniadas Ebit | Nielsen. Essas pesquisas podem ser:</p>
            <p className="lead"><b>2.1</b> Pesquisa de satisfação de compra (Clicando no banner Ebit | Nielsen que aparece logo após finalizar a sua compra no e-commerce)</p>
            <p className="lead"><b>2.2</b> Pesquisa de Satisfação de entrega (Que chegará em seu e-mail caso tenha respondido a pesquisa de compra inicialmente e autorizado o envio)</p>
            <p className="lead"><b>Observação:</b> a pesquisa de Satisfação de Entrega só chegará se, ao ter respondido a Pesquisa de Satisfação de Compra, tiver selecionado a opção de receber outras pesquisas Ebit | Nielsen</p>
            <img src="" alt="" />
          </div>
        </div>

        <div className="sorteio__passos">
          <div className="passos-detalhes">
            <h6>3. outras pesquisas ebit | nielsen</h6>
            <p className="lead">Respondendo outras pesquisas autorizadas</p>
          </div>
          <div className="passos-infos">
            <p className="lead">Respondendo pesquisas especiais que também, com sua autorização, podem ser encaminhadas ao seu e-mail</p>
            <img src="" alt="" />
          </div>
        </div>

      </div>

      <div className="ganhadores container" >
        <h2>Como saberei se eu ganhei?</h2>

        <div >
          <img src={ImgCons3} alt="Foto Ganhadores" />
          <p className="lead">
          Notificaremos o ganhador por meio do e-mail cadastrado, solicitando os dados necessários para o envio do prêmio (cartão presente) e as devidas instruções de uso. Além disso, a divulgação dos ganhadores dos sorteios podem ser verificados na página Últimos Ganhadores, toda quinta-feira, exceto feriados.
            <br />
            <b>Veja quem <a href=" https://company.ebit.com.br/concorra-premios/ultimos-ganhadores">já ganhou!</a></b>
          </p>

        </div>
      </div>

      <MedalsEbit />

      <div className="medal-true container" >
        <h2>Como saber se a medalha é verdadeira</h2>

        <div >
          <p className="lead">Sempre que encontrar nossa medalha em um site de e-commerce, clique nela e espere carregar a página da loja virtual no site da Ebit | Nielsen. É desta maneira que atestamos se a  medalha é verdadeira.</p>
          <img src={ImgCons} alt="Foto Ganhadores" />
        </div>
      </div>

      <div className="company container">
        <h2>
          encontre a melhor loja ebit | nielsen para suas compras online
        </h2>
        <div>
          <Link to="/reputacao-de-lojas">Clique aqui para verificar todas as lojas coloboradoras Ebit | Nielsen!</Link>
        </div>
      </div>

      <div className="login container">

        <div>
          <h2>Ficou alguma dúvida?</h2>
          <h5><a href="https://ebit.zendesk.com/hc/pt-br">Entre em contato conosco</a></h5>
        </div>

        {/* <div>
          <a href="https://ebit.com.br/#login" target="_blank"> <img src={ImgLogin} alt="Icone login" /></a>
        </div> */}
      </div>

      <Footer></Footer>
    </>
  );
}

export default Consumidor;