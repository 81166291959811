import React from "react";
import { Link } from "react-router-dom";
import "./style.scss";

import Menu from "../../components/Menu";

import Footer from "../../components/Footer";
import Premio from "../../assets/imagens/logo-awards-white.8839fedf.png";

import BannerPremio3 from "../../assets/imagens/Logo-PremioEbit-03.png";

function Regulamento() {
  return (
    <>
      <Menu></Menu>

      <section>
        <div className="banner-premio2021">
          <div className="bg-text-premio2021 container">
            <div className="bg-text-premio2021-div2">
              <h1>
                Regulamento Prêmio{" "}
                <span className="nowrap">NielsenIQ Ebit</span>
              </h1>
              <h3>
                Melhores do <span className="nowrap">e-commerce</span>{" "}
                brasileiro
              </h3>
            </div>
          </div>
        </div>
      </section>
      <div className="div-sub-color" />

      <div className="container border-top-silver content-tips ">
        <div className="regulamento-concorra">
          <p>
            <strong className="title_regulation">
              Regulamento - Prêmio Ebit Melhores Lojas do E-commerce
            </strong>
          </p>
          <p style={{marginTop: "50px"}}>1 Seleção</p>
          <p style={{textAlign:'justify'}}>
            1.1 - O Prêmio NielsenIQ Ebit Melhores Lojas do E-commerce 2024, tem como intuito promover e premiar as melhores lojas virtuais brasileiras no ano.
          </p>
          <p style={{textAlign:'justify'}}>
            O Prêmio NielsenIQ Ebit Melhores Lojas do E-commerce 2024 reconhece as melhores lojas virtuais brasileiras que são premiadas de acordo com duas segmentações:
          </p>
          <p style={{textAlign:'justify'}}>
            (a) Melhores Lojas do E-commerce de 2024, que obtiveram a melhor performance nos critérios técnicos avaliados nas Pesquisas Ebit, conforme a cláusula 1.4;
          </p>
          <p style={{textAlign:'justify'}}>
            (b) Lojas favoritas do E-commerce de 2024, por votação aberta ao público, disponível na página dentro do site da Ebit (https://company.ebit.com.br/premio-ebit/vote) no período entre (06/05/2024 a 04/06/2024). O Prêmio Lojas Favoritas de 2024, apesar de fazer parte da mesma iniciativa da Ebit (Prêmio NielsenIQ Ebit Melhores Lojas do E-commerce), tem caráter independente na seleção dos ganhadores e atribuição do prêmio.
          </p>
          <p style={{textAlign:'justify'}}>
            1.3 - A elegibilidade e a seleção das lojas ganhadoras são totalmente imparciais, e seguem os critérios definidos nas cláusulas a seguir.
          </p>
          <p style={{textAlign:'justify'}}>
            1.4 - Para a premiação das Melhores Lojas do E-commerce de 2024, serão considerados os seguintes critérios:
          </p>
          <p style={{textAlign:'justify'}}>
            (a) Lojas com venda de bens de consumo colaboradoras da NielsenIQ Ebit; 
          </p>
          <p style={{textAlign:'justify'}}>
            (b) Para a categoria Diamante a loja precisa ter no mínimo 184 dias com diamante entre os dias 01/04/2023 até 31/03/2024.
          </p>
          <p style={{textAlign:'justify'}}>
            (c) Indicadores de desempenho, avaliados:
          </p>
          <ul style={{paddingLeft:'20px'}}>
            <li>Porcentagem de entregas realizadas no prazo; </li>
            <li>
              Porcentagem de consumidores que voltariam a comprar nesta loja;
            </li>
            <li>
              Índice de NPS (Net Promoter Score, que mensura o grau de
              satisfação e fidelidade dos consumidores);
            </li>
            <li>Número de pesquisas Ebit.</li>
          </ul>
          <p style={{textAlign:'justify'}}>
            1.5 - Serão atribuídas categorias da premiação por medalha (Categorias de Prêmio Melhor Loja Diamante e Top 5 Diamante), de acordo com a medalha Diamante conquistada nos últimos 12 meses.
          </p>
          <p style={{textAlign:'justify'}}>
            1.6 - Para seleção da Melhor Loja do E-commerce na Categoria Diamante e Top 5 Diamante, o critério será:
          </p>
          <p style={{textAlign:'justify'}}>
            (a) Para a categoria Diamante a loja precisa ter no mínimo 184 dias com diamante entre os dias 01/04/2023 até 31/03/2024.
          </p>
          <p style={{textAlign:'justify'}}>
            1.7 - Para a premiação da Melhor Loja do E-commerce de 2024 na Categoria Experiência Mobile, o critério analisado será:
          </p>
          <p style={{textAlign:'justify'}}>
            (a) Melhor desempenho para vendas realizadas por dispositivos móveis considerando os mesmos critérios gerais estipulados na cláusula 1.4, item "c" deste contrato.
          </p>
          <p style={{textAlign:'justify'}}>
            1.8 - A Premiação da Loja favorita de 2024 tem a finalidade de premiar as lojas mais votadas nas categorias: Alimentos e Bebidas; Aplicativos de Entrega; Autosserviço; Bebês e brinquedos; Casa, Decoração e Ferramentas; Departamento; Cosméticos e Perfumaria; Cross Border; Educação,Livros & Papelaria; Moda e Acessórios; Pet Shop; Farmácia &Saúde; Sports; Eletro Eletrônicos , por meio de voto popular.
          </p>
          <p style={{textAlign:'justify'}}>
            1.9 - Para seleção das Lojas favoritas de 2024 os critérios de elegibilidade serão:
          </p>
          <p style={{textAlign:'justify'}}>
            (a) Lojas com venda de bens de consumo colaboradoras da NielsenIQ Ebit ou Convidadas;
          </p>
          <p style={{textAlign:'justify'}}>
            (b) Para concorrer ao prêmio em determinada categoria, a loja deve ter sido escolhida para a categoria pelo Ebit.
          </p>
          <p style={{textAlign:'justify'}}>
            1.10 - Caso sejam constatadas inconsistências nas pesquisas (contrárias ao Manual de Boa Práticas da Ebit) ou na votação (vide cláusula 8.1, item "b"), a Ebit poderá, a seu critério, excluir a loja da premiação, sem necessidade de qualquer aviso prévio.
          </p>
          <p style={{textAlign:'justify'}}>
            1.11 A quantidade de lojas de cada categoria é definida pela Ebit.
          </p>
          <p>2. Como funcionará </p>
          <p style={{textAlign:'justify'}}>
            2.1 - Para as lojas: As lojas pré-selecionadas pela Ebit, de acordo com os critérios estabelecidos pela cláusula 1, serão submetidas à votação popular no período de 06/05/2024 a 04/06/2024 no site da Ebit (https://company.ebit.com.br/premio-ebit/vote), podendo ser interrompida ou prorrogada de acordo com determinação interna da Ebit. Caso isso ocorra, os participantes serão previamente notificados.
          </p>
          <p style={{textAlign:'justify'}}>
            2.1.1 - As lojas receberão um kit personalizado da votação, que poderá ser disponibilizado na homepage da loja, para divulgarem a votação a seus consumidores. A divulgação deste material limita-se à duração da votação.
          </p>
          <p style={{textAlign:'justify'}}>
            2.1.2 - As lojas participantes poderão disponibilizar um banner em sua homepage para que os usuários sejam direcionados à página de votação no site da Ebit (https://company.ebit.com.br/premio-ebit/vote).
          </p>
          <p style={{textAlign:'justify'}}>
            2.1.3 - As lojas também poderão fazer campanhas de incentivo junto aos seus clientes (e-mail marketing, banner ou DHTML na homepage, Redes Sociais) para que votem, sendo que todas essas ações devem direcionar à página de votação no site da Ebit (https://company.ebit.com.br/premio-ebit/vote).
          </p>
          <p style={{textAlign:'justify'}}>
            2.2 - Para os usuários: Poderão participar e votar na premiação das Lojas favoritas de 2024 qualquer pessoa física, utilizando e-mail e CPF para validação. Atenção! Cada usuário poderá votar em apenas 1 (uma) loja por categoria de premiação, e uma única vez.
          </p>
          <p>3. Como Votar</p>
          <p style={{textAlign:'justify'}}>
            3.1 -Por meio do site Ebit: O internauta poderá acessar a votação, no endereço https://company.ebit.com.br/premio-ebit/vote, ou também por meio dos banners personalizados encontrados em páginas do site Ebit (www.ebit.com.br), nos sites de parceiros (Nielsen) e nos sites das lojas participantes.
          </p>
          <p>4. Período de Votação</p>
          <p style={{textAlign:'justify'}}>
          A votação para a escolha das Lojas favoritas de 2024, será iniciada em 06/05/2024 e permanecerá ativa até às 23:59hs (horário de Brasília - DF) do dia 04/06/2024, podendo ser interrompida ou prorrogada de acordo com determinação interna da Ebit que, caso ocorra, será comunicada aos participantes. Após esse período, não será mais possível votar em nenhuma loja.
          </p>
          <p>5. Desempate </p>
          <p style={{textAlign:'justify'}}>
            5.1 - Para critério de desempate, serão considerados os seguintes itens da Pesquisa de Satisfação da Ebit, nos últimos 12 meses, necessariamente nessa ordem:
          </p>
          <p style={{textAlign:'justify'}}>
            (a) NPS;
          </p>
          <p style={{textAlign:'justify'}}>
            (b) Probabilidade de voltar a comprar na loja (porcentagem);
          </p>
          <p style={{textAlign:'justify'}}>
            (c) Quantidade de Pesquisas.
          </p>
          <p>6. Premiação das lojas vencedoras</p>
          <p style={{textAlign:'justify'}}>
            6.1 - A Ebit promoverá um evento privado em 11 de Junho de 2024 (sujeito a alteração devido a agenda e disponibilidade do local do evento) ao para a entrega dos prêmios às lojas virtuais vencedoras da premiação de 2024.
          </p>
          <p style={{textAlign:'justify'}}>
            6.2 - O Prêmio tem caráter cultural, sem qualquer modalidade de sorteio, e visa a identificar, valorizar e divulgar experiências no mercado de e-commerce planejadas e executadas por lojas de destaque no mercado de varejo online.
          </p>
          <p style={{textAlign:'justify'}}>
            6.3 - As lojas vencedoras receberão como prêmio o Troféu NielsenIQ Ebit Melhores Lojas do E-commerce 2024 ou Troféu NielsenIQ Ebit Loja Favorita.
          </p>
          <p style={{textAlign:'justify'}}>
            6.4 - Em nenhuma hipótese os prêmios poderão ser substituídos, sendo pessoais e intransferíveis.
          </p>
          <p style={{textAlign:'justify'}}>
            6.5 - Os representantes das lojas ganhadoras, ao receber o Prêmio, concordam em assinar termo de recebimento, fornecendo cópia do documento de identificação (RG), e aceitá-lo sem direito a futuras reivindicações à Ebit, bem como também concordam em isentar a Ebit e/ou qualquer empresa envolvida no Prêmio, suas companhias coligadas, subsidiárias e afiliadas, inclusive seus respectivos funcionários, representantes, prestadores de serviços e agentes ou qualquer outra pessoa ou organização envolvida diretamente ou indiretamente a esse Prêmio de toda e qualquer demanda ou ação legal consequente de sua participação ou recebimento e utilização de qualquer prêmio, com exceção daquelas relativas ao cumprimento, bom andamento e desfecho do Prêmio.
          </p>
          <p style={{textAlign:'justify'}}>
            6.6 - O resultado da premiação será divulgado publicamente no site www.ebit.com.br/premio-ebit no dia seguinte ao evento, qual seja 17 de Junho de 2024.
          </p>
          <p>7. Comunicação do Prêmio pelas lojas ganhadoras</p>
          <p style={{textAlign:'justify'}}>
            7.1 - Ao comunicar sua vitória no Prêmio Ebit, a loja sempre deverá, necessariamente, informar o ano em que ganhou o prêmio.
          </p>
          <p>8. Critérios de Desclassificação das lojas participantes </p>
          <p style={{textAlign:'justify'}}>
            8.1 - A loja participante da premiação será automaticamente desclassificada caso:
          </p>
          <p style={{textAlign:'justify'}}>
            Apresente inconsistência na votação da categoria do prêmio a que está concorrendo, se utilizando de outros artifícios para captação de votos que não os de forma autêntica como estipulado neste  regulamento: captação de votos diretos do consumidor real, com voto único e sem qualquer forma de incentivo àqueles que votarem.
		      </p>
          <p> </p>
          <p style={{textAlign:'justify'}}>
            8.2 - A loja que for desclassificada será comunicada de sua exclusão do Prêmio e não poderá recorrer da decisão.
          </p>
          <p style={{textAlign:'justify'}}>
            8.3 - Caso a candidata desclassificada tenha sido escolhida como vencedora do prêmio, este deverá ser transferido para a próxima loja participante melhor classificada de acordo com os critérios previstos neste Regulamento.
          </p>
          <p>9. Disposições Gerais</p>
          <p style={{textAlign:'justify'}}>
            9.1 - Ao participar da Premiação, nos termos deste Regulamento, os representantes das lojas participantes automaticamente cederão o direito de uso de sua imagem e voz por prazo indeterminado, bem como os direitos de expor, publicar, reproduzir, armazenar e/ou de qualquer outra forma delas se utilizarem.
          </p>
          <p style={{textAlign:'justify'}}>
            9.2 - As lojas concordam com a cessão referida no item anterior (9.1) e o fazem de modo expresso e em caráter gratuito, irrevogável e irretratável, desde já e de pleno direito.
          </p>
          <p style={{textAlign:'justify'}}>
            9.3 - As imagens e gravações de voz poderão ser utilizadas em, mas não limitadas a: cartazes, filmes e/ou spots, jingles e/ou vinhetas, bem como em qualquer tipo de mídia e/ou peças promocionais inclusive em televisão, rádio, jornal, cartazes, faixas, outdoors, mala-direta e na Internet, existentes ou que possam ser inventados, por todo prazo de proteção legal e sem limite de território, com exclusividade.
          </p>
          <p style={{textAlign:'justify'}}>
            9.4 - Os casos omissos e/ou eventuais controvérsias oriundas da participação no Prêmio serão submetidos à Comissão Julgadora para avaliação, sendo as suas decisões soberanas e irrecorríveis.
          </p>
          <p style={{textAlign:'justify'}}>
            9.5 - Dúvidas e informações sobre o Prêmio poderão ser esclarecidas por meio do e-mail: comercial@ebit.com.br
          </p>
          <p style={{textAlign:'justify'}}>
            9.6 - O Prêmio, bem como seu Regulamento, poderão ser alterados a critério da Ebit, mediante aviso no site www.ebit.com.br/premio-ebit.
          </p>
          <p style={{textAlign:'justify'}}>
            9.7 - Fica eleito o Centro Judiciário de Solução de Conflitos e Cidadania do Tribunal de Justiça do Estado de São Paulo (CEJUSC/TJSP) para dirimir eventuais controvérsias em sessão(ões) de conciliação ou mediação extrajudicial.
          </p>
          <p style={{textAlign:'justify'}}>
            9.8 - Não sendo resolvida a questão na forma da cláusula 9.7, fica, desde já, eleito o Foro da Comarca da Capital do Estado de São Paulo para dirimir quaisquer questões pendentes.
          </p>
        </div>
      </div>
      <Footer></Footer>
    </>
  );
}

export default Regulamento;
