import React from 'react';
import ReactDOM from 'react-dom';
import store from './store'
import App from './App/App';


ReactDOM.render(
  <App/>,
  document.getElementById('root')
);

