import React, { useEffect, useState } from 'react'
import './styles.scss'
import companyServices from '../../services/companyServices'
import { actions as companyAction } from '../../store/modules/company/actions'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useHistory } from 'react-router-dom'
import Menu from '../../components/Menu'
import CompanyBoxReputation from '../../Company/components/CompanyBoxReputation'
import CompanyHeader from '../../Company/components/CompanyHeader'
import CompanyCommentaries from '../../Company/components/CompayCommentaries'
import CompanyInfos from '../../Company/components/CompanyInfos'
import Footer from '../../components/Footer'

const CompanyReputationDetails = () => {

  const history = useHistory();
  const { urlName } = useParams();
  const dispatch = useDispatch();
  const { selectedCompany } = useSelector((state) => state.company)

  const [company, setCompany] = useState({})
  const [firstLoad, setFirstLoad] = useState(true)

  useEffect(() => {
    if (Object.keys(selectedCompany).length === 0) {
      companyServices.getByUrlName(urlName)
        .then(
          (_company) => { // Retorno da API
            dispatch(companyAction.setSelectedCompany(_company))
            setFirstLoad(false)
            const result = parseInt(urlName)
            if (typeof result === "number") {
              history.push(`/${_company.EbitUrlName}`)
            }
          }
        )
    }
  }, [])

  return (
    <>
      <Menu />
      <div style={{width: "100%", height: "75px", backgroundColor: "#000", marginTop: "-75px"}}></div>
      <div className="reputation-details">

        {selectedCompany.Id ? (
          <>
            <div className="reputation-details__header">
              <CompanyHeader />
            </div>

            <div className="reputation-details__box-reputation">
              <CompanyBoxReputation />
            </div>
            <div className="reputation-details__commentaries">
              <CompanyCommentaries />
            </div>
            <div className="reputation-details__social">
              <CompanyInfos />
            </div>
          </>
        ) :
          (
            !firstLoad ?
              <div className="pag-error container">
                <h1>Desculpe!</h1>
                <h4>Não conseguimos encontrar a página que está procurando...</h4>
              </div>
              :
              <div className="pag-error container">
                <h1>Carregando...</h1>
              </div>
          )
        }
      </div>
      <Footer />
    </>
  );
}

export default CompanyReputationDetails;