import React, { useEffect, useState, useRef } from 'react'
import './styles.scss'
import { useSelector } from 'react-redux'


const CompanyHeader = () => {
  const { selectedCompany } = useSelector((state) => state.company)


  const defaultLogoUrl = 'https://newimg.ebit.com.br/ebitBR/imagens/logo-lojas/img_ndisp.gif'

  const logoCompany = (companyId) => {
    return `https://newimg.ebit.com.br/ebitBR/imagens/logo-lojas/logo_${companyId}.gif`
  }

  return (
    <>
      {selectedCompany &&
        <>
          <div className="banner-company container">
            <div className="banner-company__logo-company">
              <img
                src={logoCompany(selectedCompany.Id)}
                alt={selectedCompany.CompanySearch}
                onError={
                  (e) => {
                    e.target.onerror = null;
                    e.target.src = defaultLogoUrl
                  }
                } />

            </div>
            <div className="banner-company__name-company">
              <span>{selectedCompany.CompanyName}</span>
            </div>
          </div>
        </>
      }
    </>
  )
}

export default CompanyHeader