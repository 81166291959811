import React, { useEffect, useState } from 'react'
import './styles.scss'
import { useDispatch, useSelector } from 'react-redux'
import companyServices from '../../../services/companyServices'
import { actions as companyAction } from '../../../store/modules/company/actions'
import CommentsSkeleton from '../CommentsSkeleton'

//import { retry } from 'redux-saga/effects'


const CompanyCommentaries = () => {
  const {
    selectedCompany,
    commentariesType,
    commentaries,
  } = useSelector((state) => state.company)

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(companyAction.setCommentariesType(''))
  }, [])


  useEffect(() => {
    dispatch(companyAction.getAsyncCommentaries({
      companyId: selectedCompany.Id,
      type: commentariesType,
      offset: 0,
      qty: 100,
    }))
  }, [commentariesType])


  const customClass = (type = '') => {
    return type === commentariesType ? 'active' : ''
  }

  return (
    <>
      <div className="user-commentaries">
        <div className="user-commentaries__header">
          <h3>Avaliações dos Consumidores </h3>
          <ul>
            <li
              className={`select-t ${customClass('')}`}
              onClick={() => { dispatch(companyAction.setCommentariesType('')) }}
            >
              Todos
            </li>
            <li
              className={`select-e ${customClass('E')}`}
              onClick={() => { dispatch(companyAction.setCommentariesType('E')) }}
            >
              Elogios
            </li>
            <li
              className={`select-r ${customClass('R')}`}
              onClick={() => { dispatch(companyAction.setCommentariesType('R')) }}
            >
              Reclamações
            </li>
          </ul>
        </div>

        {
          commentaries.loading
            // ? <CommentsSkeleton />
            ? 'Carregando...'
            : (commentaries.data || []).map(
              (comment) => (
                <div className="user-commentaries__commentary">
                  <div className="user-commentaries__commentary__info">
                    {/* <img src="" className="user-avatar" alt="" /> */}
                    <div>
                      {/* <span className="fa fa-smile-o"></span> */}
                      
                      <p className="user-name">
                        {comment.Name  || 'Anônimo'}
                      </p>
                    </div>
                    {/* <p className="type-commentary">Elogio</p> */}
                    <p className="date-commentary">
                      {new Date(comment.AnswerDate).toLocaleDateString('pt-BR')}
                    </p>
                  </div>

                  <p className="user-commentary">
                    {`"${comment.Commentary}"`}
                  </p>

                  {/* <div className="user-commentaries__commentary__satisfaction">
                    <p>Comentário foi útil?</p>
                    <div className="btn-satisfaction">
                      <button className="like-commentary-up">
                        <span className="fa fa-thumbs-o-up">
                          {` ${comment.Likes} `}
                        </span>
                      </button>
                      <button className="like-commentary-down">
                        <span className="fa fa-thumbs-o-down">
                          {` ${comment.Dislikes} `}
                        </span>
                      </button>
                    </div>
                  </div> */}

                </div>
              )
            )
        }
        {
          commentaries.error && (<h2>Ops... Tivemos um problema!</h2>)
        }
      </div>
    </>
  )
}

export default CompanyCommentaries