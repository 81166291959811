import React from "react";
import PropTypes from "prop-types";
import { Link, useHistory } from "react-router-dom";
import "./styles.scss";
import { actions as companyAction } from "../../../store/modules/company/actions";
import { useDispatch, useSelector } from "react-redux";

import Diamante from "../../../assets/imagens/md-Diamante.png";
import Gold from "../../../assets/imagens/medalhas2020-reputacao-22.68a52cb9.png";
import Silver from "../../../assets/imagens/medalhas2020-reputacao-21.108fd159.png";
import Bronze from "../../../assets/imagens/medalhas2020-reputacao-20.f83bd0e2.png";
import Avaliacao from "../../../assets/imagens/medalhas2020-reputacao-19.png";
import Inativa from "../../../assets/imagens/medalhas2020-reputacao-24.png";

import ProgressBar from "react-bootstrap/ProgressBar";

const medals = () => ({
  Diamante: Diamante,
  Ouro: Gold,
  Prata: Silver,
  Bronze: Bronze,
  EmAvaliacao: Avaliacao,
  Inativada: Inativa,
});

const CompanyBox = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { selectedCompany } = useSelector((state) => state.company);

  const defaultLogoUrl =
    "https://newimg.ebit.com.br/ebitBR/imagens/logo-lojas/img_ndisp.gif";

  const logoCompany = (companyId) => {
    return `https://newimg.ebit.com.br/ebitBR/imagens/logo-lojas/logo_${companyId}.gif`;
  };

  const { company, loading } = props;

  const redirectToDetails = (urlName) => {
    dispatch(companyAction.setSelectedCompany({}));
    history.push(`/${urlName}`);
  };

  return (
    <>
      <div
        onClick={() => redirectToDetails(company.EbitUrlName)}
        className="company-card"
        key={company.Id}
      >
        <div className="company-card__content">
          <div className="company-card__content__img">
            {/* LOGO  */}
            <div>
              <img
                className="company-card__content__img__logo"
                src={logoCompany(company.Id)}
                alt={company.CompanySearch}
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src = defaultLogoUrl;
                }}
              />
            </div>

            {/* COMPANY NAME    */}
            <div>
              <p
                style={{
                  fontFamily: "Montserrat",
                  fontWeight: 700,
                  fontSize: "20px",
                }}
              >
                {company.CompanyName}
              </p>
            </div>

            {/* Medal    */}
            <div className="rebranding-01">
              <p>E-commerce {company.Medal}</p>
              <img src={medals()[company.Medal]} />
            </div>
          </div>

          <div className="company-card__content__infos">
            {/* <p>{company.CompanyName}</p>
                        <img src={medals()[company.Medal]} />
                        <p>{company.Medal}</p> */}

            <div className="company-card__content__infos__display-percentage">
              <span className="company-card__content__infos__display-percentage__satisfaction">
                {/* <p className="fa fa-heart-o company-card__content__infos__display-percentage__satisfaction__icons return-purchase" /> 
                <p className="company-card__content__infos__display-percentage__satisfaction__percentage">
                  {company.ReturnPurchase.toLocaleString("pt-BR", {
                    maximumFractionDigits: 0,
                  }) + "%"}
                </p> */}
                {/* <progress value={company.ReturnPurchase} max={100}></progress> */}

                <ProgressBar
                  now={company.ReturnPurchase}
                  label={
                    company.ReturnPurchase.toLocaleString("pt-BR", {
                      maximumFractionDigits: 0,
                    }) + "%"
                  }
                />
                <p>Compraria novamente</p>
              </span>

              <span className=" company-card__content__infos__display-percentage__satisfaction">
                {/* <p className="fa fa-calendar-check-o company-card__content__infos__display-percentage__satisfaction__icons delivery-time" /> 
                <p className="company-card__content__infos__display-percentage__satisfaction__percentage">
                  {company.DeliveryTime.toLocaleString("pt-BR", {
                    maximumFractionDigits: 0,
                  }) + "%"}
                </p>*/}

                <ProgressBar
                  now={company.DeliveryTime}
                  label={
                    company.DeliveryTime.toLocaleString("pt-BR", {
                      maximumFractionDigits: 0,
                    }) + "%"
                  }
                />
                <p>Entregas no prazo</p>
              </span>

              <span className="company-card__content__infos__display-percentage__satisfaction">
                {/* <p className="fa fa-comments-o company-card__content__infos__display-percentage__satisfaction__icons friend-recommendation" /> 
                <p className="company-card__content__infos__display-percentage__satisfaction__percentage">
                  {company.FriendRecommendation.toLocaleString("pt-BR", {
                    maximumFractionDigits: 0,
                  }) + "%"}
                </p>*/}
                <ProgressBar
                  now={company.FriendRecommendation}
                  label={
                    company.FriendRecommendation.toLocaleString("pt-BR", {
                      maximumFractionDigits: 0,
                    }) + "%"
                  }
                />
                <p>Indicaria aos amigos</p>
              </span>

              <div className="company-card__content__infos__quantity-surveys">
                <p style={{ marginTop: "15px", marginBottom: "-10px" }}>
                  <strong className="precent-survey">
                    {company.QuantitySurveys}
                  </strong>
                </p>
                <p>Avaliações recebidas</p>
              </div>
            </div>

            {/* <span className="company-card__content__infos__quantity-surveys">
              <p>
                <strong>{company.QuantitySurveys}</strong> avaliações recebidas
                pela Ebit!
              </p>
            </span> */}
          </div>
        </div>
      </div>
    </>
  );
};

CompanyBox.propTypes = {
  company: PropTypes.shape().isRequired,
  loading: PropTypes.bool,
};

CompanyBox.defaultProps = {
  loading: false,
};

export default CompanyBox;
