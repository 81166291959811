const typeActions = (scope, untypedActions) => {
    const actions = {}
    const types = {}

    const keys = Object.keys(untypedActions)

    keys.forEach((key) => {
        const TYPE = key.replace(/([A-Z])/g, '_$1').toUpperCase()
        types[TYPE] = `${scope}/${TYPE}`

        actions[key] = (...args) => ({
            ...untypedActions[key](...args),
            type: types[TYPE],
        })
    })

    return {
        actions,
        types,
    }
}

export default typeActions