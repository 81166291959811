import {
    call,
    put,
    takeLatest,
    all
} from 'redux-saga/effects'

import api from '../../../api/api'
import {
    actions,
    types
} from './actions'

function* getCommentaries({
    payload
}) {
    yield put(actions.setCommentaries({
        loading: true,
        error: false
    }))

    try {
        const response = yield call(
            api.get,
            '/getCommentaries', {
                params: payload
            }
        )
        yield put(actions.setCommentaries({
            data: response.data.Data
        }))

    } catch (error) {
        yield put(actions.setCommentaries({
            error: true
        }))
    } finally {
        yield put(actions.setCommentaries({
            loading: false
        }))
    }
}

export default all([
    takeLatest(
        types.GET_ASYNC_COMMENTARIES,
        getCommentaries,
    ),
])