import React from "react";
import "./style.scss";

import BannerPremio3 from "../../assets/imagens/Logo-PremioEbit-03.png";
import Footer from "../../components/Footer";
import Menu from "../../components/Menu";

import LogoGPA from "../../assets/imagens/awards/GPA-log.png";
import LogoRD from "../../assets/imagens/awards/RD-1.png";

function AwardWinners() {
  return (
    <>
      <Menu />
      <section>
        <div className="banner-premio2021">
        <div className="bg-text-premio2021 container">
            <div className="bg-text-premio2021-div2">
              <h1>
                Ganhadores Prêmio{" "}
                <span className="nowrap">NielsenIQ Ebit</span>
              </h1>
              <h3>
                Melhores do <span className="nowrap">e-commerce</span>{" "}
                brasileiro
              </h3>
            </div>
          </div>
          </div>        
      </section>
      <div className="div-sub-color" />

      {/* Melhor Loja */}
      <section className="award-winners container">
        <h2>Lojas premiadas 2021</h2>
        <div className="winners-class">
          <h3>Melhor loja</h3>
          <small>Com base no desempenho de 2020/2021*</small>
        </div>

        <a
          href="https://www.ebit.com.br/59301"
          target="_blank"
          className="award-winners__card-award"
          style={{ borderColor: "#3582ea" }}
        >
          <div className="card-store-winner">
            <div>
              <img
                src="https://newimg.ebit.com.br/ebitBR/imagens/logo-lojas/logo_59301.gif"
                alt="Logo loja"
              />
            </div>
            <p style={{ color: "#3582ea", fontWeight: 500, fontSize: 18 }}>
              Melhor loja Diamante
            </p>
          </div>
        </a>
      </section>

      {/* Top 5 Diamante */}
      <section className="award-winners container">
        <div className="winners-class">
          <h3>Melhores lojas</h3>
          <p>Top 5 Diamante</p>
          <small>Classificadas em ordem alfabética</small>
        </div>

        <div className="bloco-stores-winners">
          <a
            href="https://www.ebit.com.br/55110"
            target="_blank"
            className="award-winners__card-award"
          >
            <div className="card-store-winner">
              <div>
                <img
                  src="https://newimg.ebit.com.br/ebitBR/imagens/logo-lojas/logo_55110.gif"
                  alt="Logo loja"
                />
              </div>
              <p>Top 5 Diamante</p>
            </div>
          </a>

          <a
            href="https://www.ebit.com.br/57248"
            target="_blank"
            className="award-winners__card-award"
          >
            <div className="card-store-winner">
              <div>
                <img
                  style={{ width: 100 }}
                  src="https://newimg.ebit.com.br/ebitBR/imagens/logo-lojas/logo_57248.gif"
                  alt="Logo loja"
                />
              </div>
              <p>Top 5 Diamante</p>
            </div>
          </a>

          <a
            href="https://www.ebit.com.br/33128"
            target="_blank"
            className="award-winners__card-award"
          >
            <div className="card-store-winner">
              <div>
                <img
                  src="https://newimg.ebit.com.br/ebitBR/imagens/logo-lojas/logo_33128.gif"
                  alt="Logo loja"
                />
              </div>
              <p>Top 5 Diamante</p>
            </div>
          </a>

          <a
            href="https://www.ebit.com.br/59301"
            target="_blank"
            className="award-winners__card-award"
          >
            <div className="card-store-winner">
              <div>
                <img
                  src="https://newimg.ebit.com.br/ebitBR/imagens/logo-lojas/logo_59301.gif"
                  alt="Logo loja"
                />
              </div>
              <p>Top 5 Diamante</p>
            </div>
          </a>

          <a
            href="https://www.ebit.com.br/59973"
            target="_blank"
            className="award-winners__card-award"
          >
            <div className="card-store-winner">
              <div>
                <img
                  src="https://newimg.ebit.com.br/ebitBR/imagens/logo-lojas/logo_59973.gif"
                  alt="Logo loja"
                />
              </div>
              <p>Top 5 Diamante</p>
            </div>
          </a>
        </div>
      </section>

      {/* Melhor Expereiencia Mobile */}
      <section className="award-winners container">
        <div className="winners-class">
          <h3>Melhor Experiência Mobile</h3>
        </div>

        <a
          href="https://www.ebit.com.br/33128"
          target="_blank"
          className="award-winners__card-award"
        >
          <div className="card-store-winner">
            <div>
              <img
                src="https://newimg.ebit.com.br/ebitBR/imagens/logo-lojas/logo_33128.gif"
                alt="Logo loja"
              />
            </div>
            <p>Melhor Experiência Mobile</p>
          </div>
        </a>
      </section>

      {/*10 Ganhadores por Categoria */}
      <section className="award-winners container">
        <div className="winners-class">
          <h3>Lojas Mais Queridas</h3>
          <small>Escolhidas por votação popular</small>
        </div>

        <a
          style={{ marginBottom: 40 }}
          href="https://www.ebit.com.br/637"
          target="_blank"
          className="award-winners__card-award"
        >
          <div className="card-store-winner">
            <div>
              <img
                src="https://newimg.ebit.com.br/ebitBR/imagens/logo-lojas/logo_637.gif"
                alt="Logo loja"
              />
            </div>
            <p>Loja Diamante</p>
          </div>
        </a>

        <div className="bloco-stores-winners">
          <a
            href="https://www.ebit.com.br/827"
            target="_blank"
            className="award-winners__card-award"
          >
            <div className="card-store-winner">
              <div>
                <img
                  style={{ width: 102 }}
                  src="https://newimg.ebit.com.br/ebitBR/imagens/logo-lojas/logo_827.gif"
                  alt="Logo loja"
                />
              </div>
              <p>Alimentos e Bebidas</p>
            </div>
          </a>

          <a
            href="https://www.ebit.com.br/524"
            target="_blank"
            className="award-winners__card-award"
          >
            <div className="card-store-winner">
              <div>
                <img
                  src="https://newimg.ebit.com.br/ebitBR/imagens/logo-lojas/logo_524.gif"
                  alt="Logo loja"
                />
              </div>
              <p>Bebês e Brinquedos</p>
            </div>
          </a>

          <a
            href="https://www.ebit.com.br/637"
            target="_blank"
            className="award-winners__card-award"
          >
            <div className="card-store-winner">
              <div>
                <img
                  src="https://newimg.ebit.com.br/ebitBR/imagens/logo-lojas/logo_637.gif"
                  alt="Logo loja"
                />
              </div>
              <p>Casa, Decoração e Ferramentas</p>
            </div>
          </a>

          <a
            href="https://www.ebit.com.br/2083"
            target="_blank"
            className="award-winners__card-award"
          >
            <div className="card-store-winner">
              <div>
                <img
                  src="https://newimg.ebit.com.br/ebitBR/imagens/logo-lojas/logo_2083.gif"
                  alt="Logo loja"
                />
              </div>
              <p>Cosméticos e Perfumaria</p>
            </div>
          </a>

          <a
            href="https://www.ebit.com.br/637"
            target="_blank"
            className="award-winners__card-award"
          >
            <div className="card-store-winner">
              <div>
                <img
                  src="https://newimg.ebit.com.br/ebitBR/imagens/logo-lojas/logo_637.gif"
                  alt="Logo loja"
                />
              </div>
              <p>Eletro Eletrônicos</p>
            </div>
          </a>

          <a
            href="https://www.ebit.com.br/648"
            target="_blank"
            className="award-winners__card-award"
          >
            <div className="card-store-winner">
              <div>
                <img
                  src="https://newimg.ebit.com.br/ebitBR/imagens/logo-lojas/logo_648.gif"
                  alt="Logo loja"
                />
              </div>
              <p>Livros</p>
            </div>
          </a>

          <a
            href="https://www.ebit.com.br/101927"
            target="_blank"
            className="award-winners__card-award"
          >
            <div className="card-store-winner">
              <div>
                <img
                  style={{ width: 90 }}
                  src="https://newimg.ebit.com.br/ebitBR/imagens/logo-lojas/logo_101927.gif"
                  alt="Logo loja"
                />
              </div>
              <p>Moda e Acessórios</p>
            </div>
          </a>

          <a
            href="https://www.ebit.com.br/16797"
            target="_blank"
            className="award-winners__card-award"
          >
            <div className="card-store-winner">
              <div>
                <img
                  src="https://newimg.ebit.com.br/ebitBR/imagens/logo-lojas/logo_16797.gif"
                  alt="Logo loja"
                />
              </div>
              <p>Pet Shop</p>
            </div>
          </a>

          <a
            href="https://www.ebit.com.br/81666"
            target="_blank"
            className="award-winners__card-award"
          >
            <div className="card-store-winner">
              <div>
                <img
                  style={{ width: 190 }}
                  src="https://newimg.ebit.com.br/ebitBR/imagens/logo-lojas/logo_81666.gif"
                  alt="Logo loja"
                />
              </div>
              <p>Saúde</p>
            </div>
          </a>
        </div>
      </section>

      {/* Reconhecimento especial */}
      <section className="award-winners container">
        <div className="winners-class">
          <h3>Reconhecimento especial</h3>
          <p>Votação popular</p>
          <small>Classificadas em ordem alfabética</small>
        </div>

        <div className="bloco-stores-winners">
          <a
            href="https://www.gpabr.com/pt/"
            target="_blank"
            className="award-winners__card-award"
          >
            <div className="card-store-winner">
              <div>
                <img src={LogoGPA} alt="Logo loja" />
              </div>
              <p>Alimentos e Bebidas</p>
            </div>
          </a>

          <a
            href="https://rd.com.br/"
            target="_blank"
            className="award-winners__card-award"
          >
            <div className="card-store-winner">
              <div>
                <img style={{ width: 230 }} src={LogoRD} alt="Logo loja" />
              </div>
              <p>Saúde</p>
            </div>
          </a>
        </div>
      </section>

      

      {/*10 Ganhadores votação popular */}
      <section className="award-winners container">
        <div className="winners-class">
          <h3>Lojas Mais Queridas</h3>
          <small>
            Pós vencedores por categoria, classificadas em ordem alfabética
          </small>
        </div>

        <div className="bloco-stores-winners">
          <a
            href="https://www.ebit.com.br/568"
            target="_blank"
            className="award-winners__card-award"
          >
            <div className="card-store-winner">
              <div>
                <img
                  style={{ width: 200 }}
                  src="https://newimg.ebit.com.br/ebitBR/imagens/logo-lojas/logo_568.gif"
                  alt="Logo loja"
                />
              </div>
              <p>Top 10 mais votadas</p>
            </div>
          </a>

          <a
            href="https://www.ebit.com.br/948"
            target="_blank"
            className="award-winners__card-award"
          >
            <div className="card-store-winner">
              <div>
                <img
                  src="https://newimg.ebit.com.br/ebitBR/imagens/logo-lojas/logo_948.gif"
                  alt="Logo loja"
                />
              </div>
              <p>Top 10 mais votadas</p>
            </div>
          </a>

          <a
            href="https://www.ebit.com.br/2254"
            target="_blank"
            className="award-winners__card-award"
          >
            <div className="card-store-winner">
              <div>
                <img
                  src="https://newimg.ebit.com.br/ebitBR/imagens/logo-lojas/logo_2254.gif"
                  alt="Logo loja"
                />
              </div>
              <p>Top 10 mais votadas</p>
            </div>
          </a>

          <a
            href="https://www.ebit.com.br/55110"
            target="_blank"
            className="award-winners__card-award"
          >
            <div className="card-store-winner">
              <div>
                <img
                  src="https://newimg.ebit.com.br/ebitBR/imagens/logo-lojas/logo_55110.gif"
                  alt="Logo loja"
                />
              </div>
              <p>Top 10 mais votadas</p>
            </div>
          </a>

          <a
            href="https://www.ebit.com.br/33128"
            target="_blank"
            className="award-winners__card-award"
          >
            <div className="card-store-winner">
              <div>
                <img
                  src="https://newimg.ebit.com.br/ebitBR/imagens/logo-lojas/logo_33128.gif"
                  alt="Logo loja"
                />
              </div>
              <p>Top 10 mais votadas</p>
            </div>
          </a>

          <a
            href="https://www.ebit.com.br/2709"
            target="_blank"
            className="award-winners__card-award"
          >
            <div className="card-store-winner">
              <div>
                <img
                  src="https://newimg.ebit.com.br/ebitBR/imagens/logo-lojas/logo_2709.gif"
                  alt="Logo loja"
                />
              </div>
              <p>Top 10 mais votadas</p>
            </div>
          </a>

          <a
            href="https://www.ebit.com.br/95038"
            target="_blank"
            className="award-winners__card-award"
          >
            <div className="card-store-winner">
              <div>
                <img
                  src="https://newimg.ebit.com.br/ebitBR/imagens/logo-lojas/logo_95038.gif"
                  alt="Logo loja"
                />
              </div>
              <p>Top 10 mais votadas</p>
            </div>
          </a>

          <a
            href="https://www.ebit.com.br/552"
            target="_blank"
            className="award-winners__card-award"
          >
            <div className="card-store-winner">
              <div>
                <img
                  style={{ width: 200 }}
                  src="https://newimg.ebit.com.br/ebitBR/imagens/logo-lojas/logo_552.gif"
                  alt="Logo loja"
                />
              </div>
              <p>Top 10 mais votadas</p>
            </div>
          </a>

          <a
            href="https://www.ebit.com.br/715"
            target="_blank"
            className="award-winners__card-award"
          >
            <div className="card-store-winner">
              <div>
                <img
                  src="https://newimg.ebit.com.br/ebitBR/imagens/logo-lojas/logo_715.gif"
                  alt="Logo loja"
                />
              </div>
              <p>Top 10 mais votadas</p>
            </div>
          </a>

          <a
            href="https://www.ebit.com.br/5197"
            target="_blank"
            className="award-winners__card-award"
          >
            <div className="card-store-winner">
              <div>
                <img
                  src="https://newimg.ebit.com.br/ebitBR/imagens/logo-lojas/logo_5197.gif"
                  alt="Logo loja"
                />
              </div>
              <p>Top 10 mais votadas</p>
            </div>
          </a>
        </div>
      </section>

      {/*10 Finalistas votação popular */}
      <section className="award-winners container">
        <div className="winners-class">
          <h3>Lojas Finalistas</h3>
          <p>Top 10 finalistas</p>
          <small>Classificadas em ordem alfabética</small>
        </div>

        <div className="bloco-stores-winners">
          <a
            href="https://www.ebit.com.br/11186"
            target="_blank"
            className="award-winners__card-award"
          >
            <div className="card-store-winner">
              <div>
                <img
                  style={{ width: 100 }}
                  src="https://newimg.ebit.com.br/ebitBR/imagens/logo-lojas/logo_11186.gif"
                  alt="Logo loja"
                />
              </div>
              <p>Top 10 finalistas</p>
            </div>
          </a>

          <a
            href="https://www.ebit.com.br/3108"
            target="_blank"
            className="award-winners__card-award"
          >
            <div className="card-store-winner">
              <div>
                <img
                  src="https://newimg.ebit.com.br/ebitBR/imagens/logo-lojas/logo_3108.gif"
                  alt="Logo loja"
                />
              </div>
              <p>Top 10 finalistas</p>
            </div>
          </a>

          <a
            href="https://www.ebit.com.br/32992"
            target="_blank"
            className="award-winners__card-award"
          >
            <div className="card-store-winner">
              <div>
                <img
                  src="https://newimg.ebit.com.br/ebitBR/imagens/logo-lojas/logo_32992.gif"
                  alt="Logo loja"
                />
              </div>
              <p>Top 10 finalistas</p>
            </div>
          </a>

          <a
            href="https://www.ebit.com.br/9874"
            target="_blank"
            className="award-winners__card-award"
          >
            <div className="card-store-winner">
              <div>
                <img
                  src="https://newimg.ebit.com.br/ebitBR/imagens/logo-lojas/logo_9874.gif"
                  alt="Logo loja"
                />
              </div>
              <p>Top 10 finalistas</p>
            </div>
          </a>

          <a
            href="https://www.ebit.com.br/898"
            target="_blank"
            className="award-winners__card-award"
          >
            <div className="card-store-winner">
              <div>
                <img
                  src="https://newimg.ebit.com.br/ebitBR/imagens/logo-lojas/logo_898.gif"
                  alt="Logo loja"
                />
              </div>
              <p>Top 10 finalistass</p>
            </div>
          </a>

          <a
            href="https://www.ebit.com.br/90321"
            target="_blank"
            className="award-winners__card-award"
          >
            <div className="card-store-winner">
              <div>
                <img
                  src="https://newimg.ebit.com.br/ebitBR/imagens/logo-lojas/logo_90321.gif"
                  alt="Logo loja"
                />
              </div>
              <p>Top 10 finalistas</p>
            </div>
          </a>

          <a
            href="https://www.ebit.com.br/3160"
            target="_blank"
            className="award-winners__card-award"
          >
            <div className="card-store-winner">
              <div>
                <img
                  src="https://newimg.ebit.com.br/ebitBR/imagens/logo-lojas/logo_3160.gif"
                  alt="Logo loja"
                />
              </div>
              <p>Top 10 finalistas</p>
            </div>
          </a>

          <a
            href="https://www.ebit.com.br/74702"
            target="_blank"
            className="award-winners__card-award"
          >
            <div className="card-store-winner">
              <div>
                <img
                  style={{ width: 200 }}
                  src="https://newimg.ebit.com.br/ebitBR/imagens/logo-lojas/logo_74702.gif"
                  alt="Logo loja"
                />
              </div>
              <p>Top 10 finalistas</p>
            </div>
          </a>

          <a
            href="https://www.ebit.com.br/10321"
            target="_blank"
            className="award-winners__card-award"
          >
            <div className="card-store-winner">
              <div>
                <img
                  src="https://newimg.ebit.com.br/ebitBR/imagens/logo-lojas/logo_10321.gif"
                  alt="Logo loja"
                />
              </div>
              <p>Top 10 finalistas</p>
            </div>
          </a>

          <a
            href="https://www.ebit.com.br/90046"
            target="_blank"
            className="award-winners__card-award"
          >
            <div className="card-store-winner">
              <div>
                <img
                  src="https://newimg.ebit.com.br/ebitBR/imagens/logo-lojas/logo_90046.gif"
                  alt="Logo loja"
                />
              </div>
              <p>Top 10 finalistas</p>
            </div>
          </a>
        </div>
      </section>

      <Footer />
    </>
  );
}

export default AwardWinners;
