import api from '../api/api'

export default {
  getAll: async (params = {}) => {
    const response = await api.get(
      '/getall', {
        params
      }
    );
    return response.data;
  },
  

  getByUrlName: async (paramValue) => {    
    // const result = parseInt(paramValue)
    const result = paramValue
    let response =""
    if(!isNaN(result)){      
      response = await api.get(
        `/GetbyCompanyById?id=${paramValue}`,
      );
    }
    else {
      response = await api.get(
        `/getbyCompanyURL?urlName=${paramValue}`,
      );
    }
    const {data} = response;
    if(data && data.Data){
      const storeUrl = data.Data.StoreUrl ? data.Data.StoreUrl : ""
      const typeMedal= data.Data.Medal.replace("Em Avaliação", "EmAvaliacao") 
      const _company = {
        ...data.Data,
        DeliveryTime: data.Data.DeliveryTime.toLocaleString('pt-BR', {
          maximumFractionDigits: 0
        }),
        ReturnPurchase: data.Data.ReturnPurchase.toLocaleString('pt-BR', {
          maximumFractionDigits: 0
        }),
        FriendRecommendation: data.Data.FriendRecommendation.toLocaleString('pt-BR', {
          maximumFractionDigits: 0
        }),
        StoreUrl: storeUrl.startsWith("http") ? storeUrl : `https://${storeUrl}`,
        
        TypeMedal: typeMedal
      }
      return _company;
    }
    return {}    
  },


  getCommentaries: async ({companyid, type, offset, qty}) => {
    const {data} = await api.get(
      '/getCommentaries',
      {
        params: {
          companyid,
          type,
          offset,
          qty
        }
      }
    )

    return data
  },


  getByName: async (params = {}) =>{
    const response = await api.get(
      '/GetByCompanyName',      
      {
        params
      }
    );
    return response.data;
  }
}