import React from 'react'
import "./style.scss"
import LogoEbit from '../../assets/imagens/NewLogo.png'
import { Button, Navbar, Nav, Form, FormControl, NavDropdown } from 'react-bootstrap'

import { Link } from 'react-router-dom'

const Menu = () => {

  return (
    <>
      {
        <Navbar className="menu-nielsen"  expand="lg">
          <div className="container">
            <Link to="/" className="nav-brand logo-ebit"><img src={LogoEbit} /></Link>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse className="justify-content-end" id="">
              <Nav className="justify-content-end">
                <a className="nav-link" href="https://nielseniq.com/global/pt/landing-page/ebit/nielseniq-ebit-brasil/solucoes/">Soluções</a>
                {/* <Link to="/sobre-nos" className="nav-link">Sobre nós</Link> */}
                {/* <Link to="/consumidor" className="nav-link">Consumidor</Link> */}
                {/* <NavDropdown title="Lojista" id="basic-nav-dropdown">
                  <NavDropdown.Item>
                    <Link to="/empresa" className="nav-link">Quero me conveniar</Link>
                  </NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item >
                    <Link to="/lojista" className="nav-link">Já sou conveniado</Link>
                  </NavDropdown.Item>                  
                </NavDropdown> */}

                <a href="https://nielseniq.com/global/pt/landing-page/ebit/nielseniq-ebit-brasil/premio-nielseniq-ebit/" className="nav-link">Prêmio Ebit</a>
                {/* <a href="https://company.ebit.com.br/webshoppers" target="_blank" className="nav-link">Webshoppers</a> */}
                <a href="https://nielseniq.com/global/pt/landing-page/ebit/nielseniq-ebit-brasil/webshoppers/" className="nav-link">Webshoppers</a>
                <a href="https://ebit.zendesk.com/hc/pt-br" target="_blank" className="nav-link">Fale Conosco</a>
                {/* <NavDropdown title="Login" id="basic-nav-dropdown">
                  <NavDropdown.Item href="https://company.ebit.com.br/user/login" target="_blank">Portal do usuário </NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item href="https://portal.ebit.com.br/" target="_blank">Portal do Lojista </NavDropdown.Item>
                </NavDropdown> */}
              </Nav>
            </Navbar.Collapse>
          </div>
        </Navbar>
      }
    </>
  )
}

export default Menu






