import React, { useState, useEffect } from "react";
import "./style.scss";
import { Link } from "react-router-dom";
// import icon1 from '../../assets/imagens/home-rep.jpg'
// import icon2 from '../../assets/imagens/home-loj.jpg'
// import icon3 from '../../assets/imagens/home-con.JPG'
// import icon4 from '../../assets/imagens/home-ins.jpg'
// import icon5 from '../../assets/imagens/home-pre.jpg'

import img1 from "../../assets/imagens/home-rep.JPG";
import img2 from "../../assets/imagens/home-loj.a504a0ba.JPG";
import img3 from "../../assets/imagens/home-con.11f170b1.JPG";
import img4 from "../../assets/imagens/home-ins.JPG";
import img5 from "../../assets/imagens/home-pre.811aae8c.JPG";
import img6 from "../../assets/imagens/home-web.6bf3b9a8.JPG";

import seta from "../../assets/imagens/icons/seta.svg";

const Personas = () => {
  return (
    <>
      <div className="banner-home-NIQ">
        <div className="bg-text-premio2021 container">
          <div className="bg-text-premio2021-div2">
            <h1>
             Visão 360° do <br /><span className="nowrap">e-commerce</span> brasileiro
            </h1>
          </div>
        </div>
      </div>
      <div className="div-sub-color" />

      <div className="container personas">
        <Link to="/reputacao-de-lojas" className="personas__div-link-personas">
          <div className="personas__div-link-personas__card">
            <h4 className="personas__div-link-personas__card__title-home">
              Reputação de lojas
            </h4>
            <p>
              Busque pela reputação das lojas online, avaliada pelos
              consumidores.
            </p>
            <div className="btn_seta">
              <img src={seta} alt="Seta para direita" />
            </div>
          </div>
        </Link>

        <a
          href="https://nielseniq.com/global/pt/landing-page/ebit/nielseniq-ebit-brasil/quero-me-tornar-parceiro-colaborador/"
          className="personas__div-link-personas"
        >
          <div className="personas__div-link-personas__card">
            <h4 className="personas__div-link-personas__card__title-home">
              Quero me tornar parceiro colaborado
            </h4>
            <p>
              A reputação NielsenIQ Ebit contribui com aumento de tráfego,
              conversão e SEO.
            </p>
            <div className="btn_seta">
              <img src={seta} alt="Seta para direita" />
            </div>
          </div>
        </a>

        <a
          href="https://nielseniq.com/global/pt/landing-page/ebit/nielseniq-ebit-brasil/ja-sou-parceiro-colaborador/"
          className="personas__div-link-personas"
        >
          <div className="personas__div-link-personas__card">
            <h4 className="personas__div-link-personas__card__title-home">
              Já sou parceiro colaborador
            </h4>
            <p>
              Credibilidade para o seu e-commerce e inteligência competitiva
              para o seu negócio, sem custo.
            </p>
            <div className="btn_seta">
              <img src={seta} alt="Seta para direita" />
            </div>
          </div>
        </a>

        <a
          href="https://nielseniq.com/global/pt/landing-page/ebit/nielseniq-ebit-brasil/sou-consumidor/"
          className="personas__div-link-personas"
        >
          <div className="personas__div-link-personas__card">
            <h4 className="personas__div-link-personas__card__title-home">
              Sou consumidor
            </h4>
            <p>
              {" "}
              A NielsenIQ Ebit está presente em várias etapas de compras online.
            </p>
            <div className="btn_seta">
              <img src={seta} alt="Seta para direita" />
            </div>
          </div>
        </a>

        <a
          href="https://nielseniq.com/global/pt/landing-page/ebit/nielseniq-ebit-brasil/premio-nielseniq-ebit/"
          className="personas__div-link-personas"
        >
          <div className="personas__div-link-personas__card">
            <h4 className="personas__div-link-personas__card__title-home">
              Prêmio NielsenIQ Ebit
            </h4>
            <p>
              {" "}
              Uma iniciativa da NielsenIQ Ebit, é a premiação mais tradicional
              desse segmento.
            </p>
            <div className="btn_seta">
              <img src={seta} alt="Seta para direita" />
            </div>
          </div>
        </a>

        <a
          href="https://nielseniq.com/global/pt/landing-page/ebit/nielseniq-ebit-brasil/webshoppers/"
          className="personas__div-link-personas"
        >
          <div className="personas__div-link-personas__card">
            <h4 className="personas__div-link-personas__card__title-home">
              Webshoppers
            </h4>
            <p>
              Confira os mais completos relatórios sobre o mercado de
              e-commerce.
            </p>
            <div className="btn_seta">
              <img src={seta} alt="Seta para direita" />
            </div>
          </div>
        </a>
      </div>
    </>
  );
};
export default Personas;
