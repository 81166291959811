import React from 'react'
import './style.scss'
import Menu from '../../components/Menu'
import CompanyList from '../../Company/components/CompaniesList'
import Footer from '../../components/Footer'

const CompanyReputation = () => {

  return (
    <>
      <Menu />    

      <section className="banner-NIQ-Reputation">
        <div className="container">
          <h2>Reputação das lojas</h2>
          <h3>Confira as avaliações dos usuários NielsenIQ Ebit</h3>
        </div>
      </section>
      <div className="div-sub-color" />

      <CompanyList/>      
      <Footer />
    </>
  )
}

export default CompanyReputation;

