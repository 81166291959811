import React from 'react'
import "./style.scss"
import { Link } from 'react-router-dom'

import LogoENIQ from '../../assets/imagens/NewLogo.png'

function Footer() {
  return (
    <>
      <div className="footer-eb-mx">
        <div className="container">
          <div className="div-ft-links">
            <div className="div-ft-links__imgLogo">
              <a href="">
                <img src={LogoENIQ} alt="Logo NielsenIQ Ebit" />
              </a>
            </div>
            <div className="div-ft-links__contacts">
              <div className="div-contactUs">
                <a className="cont-us-a1" href="https://nielseniq.com/global/pt/about-us/contact-us/" target='_blanc'>
                  Contate-nos
                </a>
                <a className="cont-us-a2" href="https://nielseniq.com/global/pt/careers/" target='_blanc'>Carreiras</a>
              </div>
              <div className="social-media">
                <a href="https://www.linkedin.com/company/nielseniq/" target="_blank" className="fa fa-linkedin" aria-hidden="true"></a>
                <a href="https://twitter.com/nielseniq" target="_blank" className="fa fa-twitter" aria-hidden="true"></a>
                <a href="https://www.facebook.com/NielsenIQ.global/" target="_blank" className="fa fa-facebook-square" aria-hidden="true"></a>
                <a href="https://www.instagram.com/nielseniq/" target="_blank" className="fa fa-instagram" aria-hidden="true"></a>
                <a href="https://www.youtube.com/channel/UCYgAtbXKtOHSPlF81g56c3w" target="_blank" className="fa fa-youtube-play" aria-hidden="true"></a>
              </div>
            </div>
          </div>

          <div className="div-ft-terms">
            <div className="div-polcy-terms">
              <a className="niq-underline-green" href="https://nielseniq.com/global/pt/legal/privacy-policy/" target='_blanc'>
                Privacidade
              </a>
              <a
                style={{ marginLeft: "14px" }}
                href="https://nielseniq.com/global/pt/legal/terms-of-use/"
                target='_blanc'
                className="term-con-a2"
              >
                Termos e Condições
              </a>
              <a
                style={{ marginLeft: "14px", borderRight: "none"}}
                href="https://nielseniq.com/global/pt/legal/website-privacy-statement/"
                target='_blanc'
              >
                Política de Cookies
              </a>
            </div>
            <div className="rights-reserved">
              <small>© 2022 Nielsen Consumer LLC. All Rights Reserved.</small>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Footer; 
