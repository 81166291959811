import React from 'react';

// import './style.scss'
import Menu from '../../components/Menu'
import Personas from '../../components/Personas'
// import Body from '../../components/Body'
import BannerCarousel from '../../components/BannerCarousel'
import Footer from '../../components/Footer'
import AboutUs from '../../components/AboutUs'



function Home() {   
  return (
       <>       
        <Menu></Menu>
        {/* <BannerCarousel></BannerCarousel> */}
        <Personas></Personas>
        <Footer></Footer>
      </>
  );
}

export default Home;