import React, { useEffect, useState, useRef } from 'react'
import './styles.scss'
import api from '../../../api/api'
import { useParams } from "react-router-dom"
import ProgressBar from 'react-bootstrap/ProgressBar'

import Diamante from '../../../assets/imagens/md-Diamante.png'
import Gold from '../../../assets/imagens/md-Ouro.png'
import Silver from '../../../assets/imagens/md-Prata.png'
import Bronze from '../../../assets/imagens/md-Bronze.png'
import Avaliacao from '../../../assets/imagens/medalhas2020-reputacao-19.png'
import Inativa from '../../../assets/imagens/medalhas2020-reputacao-24.png'


import companyServices from '../../../services/companyServices'
import { useDispatch, useSelector } from 'react-redux'

const medals = () => ({
  Diamante: Diamante,
  Ouro: Gold,
  Prata: Silver,
  Bronze: Bronze,
  EmAvaliacao: Avaliacao,
  Inativada: Inativa
})


const CompanyBoxReputation = () => {
  const { selectedCompany: company } = useSelector((state) => state.company)

  
  return (

    <>
      {company &&
        <>
          <div className="satisfaction-company">
            <div className="satisfaction-company__medal">
              <img src={medals()[company.TypeMedal]} />
              <p>{company.Medal}</p>

              <div className="aj-responsive">

                <div>
                  <div className="medal-label">
                    <span className="fa fa-heart-o heart-color"></span>
                    <span>Comprariam novamente</span>
                  </div>
                  <div className="satisfaction-company__medal__percentages ReturnPurchase">
                    <ProgressBar now={company.ReturnPurchase} label={`${company.ReturnPurchase}%`} />
                  </div>
                </div>

                <div>
                  <div className="medal-label">
                    <span className="fa fa-calendar-check-o calendar-color"></span>
                    <span>Entrega no Prazo</span>
                  </div>
                  <div className="satisfaction-company__medal__percentages DeliveryTime">
                    <ProgressBar now={company.DeliveryTime} label={`${company.DeliveryTime}%`} />
                  </div>
                </div>

                <div>
                  <div className="medal-label">
                    <span className="fa fa-comments-o comments-color"></span>
                    <span>Indicaria a amigos</span>
                  </div>
                  <div className="satisfaction-company__medal__percentages FriendRecommendation">
                    <ProgressBar now={company.FriendRecommendation} label={`${company.FriendRecommendation}%`} />
                  </div>
                </div>

              </div>



            </div>
          </div>
        </>
      }
    </>
  )
}

export default CompanyBoxReputation