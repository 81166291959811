const secureString = process.env.NODE_ENV === 'production'
  ? 'SameSite=Strict Secure'
  : 'SameSite=Strict'
const authDomain = process.env.NODE_ENV === 'production' 
  ? 'ebit.com.br'
  : ''

const TWENTY_MINUTES_IN_SECONDS = 1200

const setItem = (name, value, maxAge = TWENTY_MINUTES_IN_SECONDS) => {
  document.cookie = [
    `${name}=${encodeURIComponent(value)}`,
    'path=/',
    `domain=${authDomain}`,
    `max-age=${maxAge}`,
    secureString,
  ].join(';')
}

const getItem = (name) => {
  const cookieName = `${name}=`
  const decodedCookies = decodeURIComponent(document.cookie)
  const cookies = decodedCookies.split(';')

  for (let i = 0; i < cookies.length; i += 1) {
    let cookie = cookies[i]

    while (cookie.charAt(0) === ' ') {
      cookie = cookie.substring(1)
    }

    if (cookie.indexOf(cookieName) === 0) {
      return cookie.substring(cookieName.length, cookie.length)
    }
  }

  return null
}

const removeItem = (name) => {
  document.cookie = [
    `${name}=`,
    'path=/',
    `domain=${authDomain}`,
    'expires=Thu, 01 Jan 1970 00:00:00 UTC',
    secureString,
  ].join(';')
}

export default {
  setItem,
  getItem,
  removeItem,
}